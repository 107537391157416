import React from 'react'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { CommonSectionSettingsColorThemeEnum } from 'framework/strapi/types'
import Link from '../Link'
import { BreadCrumb } from 'framework/common/variantViewModel'

interface Props {
  layout?: string
  colorTheme?: CommonSectionSettingsColorThemeEnum
  breadCrumbs: BreadCrumb[]
}

const BreadCrumbs = ({ layout, colorTheme, breadCrumbs }: Props) => {
  const css = {
    wrapper: 'flex gap-x-1 lg:gap-x-2 items-end',
    link: 'block font-medium text-xs lg:text-[15px] hover:underline last:truncate_',
    dividerIcon: 'h-4 flex-shrink-0',
  }

  // Layout
  if (layout === 'ProductHeader') {
    css.link =
      'h-[48px] lg:h-auto -my-4 lg:my-0 flex items-center font-bold text-xs md:text-sm hover:underline last:truncate'
    css.dividerIcon = 'h-4 flex-shrink-0'
  }

  // Color Theme
  if (!colorTheme || colorTheme === 'Default') {
    css.link = classNames(css.link, 'text-primary')
    css.dividerIcon = classNames(css.dividerIcon, 'text-primary')
  }
  if ((!colorTheme || colorTheme === 'Default') && layout === 'ProductHeader') {
    css.link = classNames(css.link, 'text-primary lg:text-primary-2')
    css.dividerIcon = classNames(
      css.dividerIcon,
      'text-primary lg:text-primary-2',
    )
  }
  if (colorTheme === 'Dark') {
    css.link = classNames(css.link, 'text-white')
    css.dividerIcon = classNames(css.dividerIcon, 'text-white')
  }

  return (
    <div className={css.wrapper}>
      {breadCrumbs.map((crumb, index: number) => (
        <React.Fragment key={index}>
          <Link href={crumb.url} className={css.link}>
            {crumb.name}
          </Link>
          {index < breadCrumbs.length - 1 && (
            <ChevronRightIcon className={css.dividerIcon} />
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default BreadCrumbs
