import { AdjustmentsVerticalIcon } from '@heroicons/react/24/solid'
import Button from 'components/ui/Button'
import { ButtonSize } from 'components/ui/Button'
import { CommonSectionSettingsColorThemeEnum } from 'framework/strapi/types'
import { FC } from 'react'
import translate from 'utils/translate'

interface Props {
  filterCount: number
  colorTheme: CommonSectionSettingsColorThemeEnum
  size?: ButtonSize
  onClick: () => any
}

const FilterButton: FC<Props> = ({
  filterCount,
  colorTheme,
  size,
  onClick,
}) => {
  return (
    <Button
      colorTheme={colorTheme}
      size={size}
      iconComponent={AdjustmentsVerticalIcon}
      onClick={onClick}
    >
      <>
        {translate('filter')}
        {filterCount > 0 && (
          <div className="flex items-center justify-center w-6 h-6 leading-normal text-white rounded-full bg-primary-2">
            {filterCount}
          </div>
        )}
      </>
    </Button>
  )
}

export default FilterButton
