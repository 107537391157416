import { Dictionary } from 'utils/dictionary'
import { SearchType } from './types'
import qs from 'query-string'
import {
  contentSortOptions,
  defaultContentSortOption,
  defaultProductSortOptionCategory,
  defaultProductSortOptionSearch,
  productSortOptionsCategory,
  productSortOptionsSearch,
} from '.'

export interface SetQueryStringProps {
  type: SearchType
  query: string
  filters: Dictionary<Dictionary<boolean>>
  sort: string
}
export function setQueryString(s: SetQueryStringProps) {
  const dd: Dictionary<string | string[]> = { type: s.type }
  if (s.query) {
    dd.q = s.query
  }
  dd.sort = s.sort
  if (s.type === 'products') {
    for (const [k, v] of Object.entries(s.filters)) {
      if (!v) continue
      const values = []
      for (const [kk, vv] of Object.entries(v)) {
        if (vv) {
          values.push(kk)
        }
      }
      if (values.length) {
        dd[k] = values
      }
    }
  }
  if (window.history.pushState) {
    const searchQuery = qs.stringify(dd, { arrayFormat: 'comma' })
    const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${searchQuery}`
    window.history.pushState({ path: url }, '', url)
  }
}

interface ParseFromQueryStringProps {
  category?: string | null
  facetKeys?: string[]
}

interface ParseFromQueryStringResult {
  filters: Dictionary<Dictionary<boolean>>
  query: string
  sort: string
  type: SearchType
}

type QueryParam = string | (string | null)[] | null

export function parseFromQueryString({
  category,
  facetKeys,
}: ParseFromQueryStringProps): ParseFromQueryStringResult {
  const p = qs.parse(location.search, { arrayFormat: 'comma' })
  const t = getStringFromQuery(p.type, 'products')
  const type: SearchType = !category && t === 'content' ? 'content' : 'products'
  const query = getStringFromQuery(p.q)
  const sort = parseSortFromQueryString(type, p.sort, category)
  const filters = parseFiltersFromQueryString(type, p, facetKeys)
  return {
    sort,
    type,
    query,
    filters,
  }
}

function getStringFromQuery(q: QueryParam, defaultValue: string = '') {
  if (!q) return defaultValue
  if (Array.isArray(q)) {
    if (!q.length) return defaultValue
    return q.join(',')
  }
  return q
}

function parseSortFromQueryString(
  type: SearchType,
  sort: QueryParam,
  category?: string | null,
) {
  const s = getStringFromQuery(sort)
  if (type === 'products') {
    if (category) {
      // Category Page
      if (!s || !productSortOptionsCategory.includes(s as any)) {
        return defaultProductSortOptionCategory
      }
      return s
    }
    if (!s || !productSortOptionsSearch.includes(s as any)) {
      return defaultProductSortOptionSearch
    }
    return s
  }
  if (!s || !contentSortOptions.includes(s as any)) {
    return defaultContentSortOption
  }
  return s
}

function parseFiltersFromQueryString(
  type: SearchType,
  qs: any,
  facetKeys?: string[],
) {
  if (type === 'content') return {}
  const filters: Dictionary<Dictionary<boolean>> = {}
  for (const key of Object.keys(qs)) {
    if (!key.startsWith('kf_') || !facetKeys?.includes(key)) continue
    const v = qs[key]
    if (!v) continue
    const values = (Array.isArray(v) ? v.filter((vv) => vv) : [v]) as string[]
    filters[key] = values.reduce<Dictionary<boolean>>((a, v) => {
      a[v] = true
      return a
    }, {})
  }
  return filters
}
