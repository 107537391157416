import { PartialContent, PartialProduct } from 'framework/common/types'
import {
  ContentSortOptionsType,
  defaultContentSortOption,
  defaultProductSortOptionSearch,
  ProductSortOptionsType,
} from 'framework/elastic'
import {
  filteredProductSearch,
  initialProductSearch,
} from 'framework/elastic/products'
import {
  parseFromQueryString,
  setQueryString,
  SetQueryStringProps,
} from 'framework/elastic/querystring'
import { MappedFacet, SearchType } from 'framework/elastic/types'
import { createContext, FC, useContext, useEffect } from 'react'
import { Dictionary } from 'utils/dictionary'
import { Updater, useImmer } from 'use-immer'
import { contentSearch } from 'framework/elastic/content'

interface SearchState {
  query: string
  product: {
    sort: ProductSortOptionsType
    category: string | null
    facetKeys: string[]
    filters: Dictionary<Dictionary<boolean>>
    filterCount: number
    result: PartialProduct[]
    totalResults: number
    facets: Dictionary<MappedFacet>
    allFacets: Dictionary<MappedFacet>
  }
  content: {
    sort: ContentSortOptionsType
    result: PartialContent[]
    totalResults: number
  }
  type: SearchType
  loading: boolean
  initialized: boolean
  error: string | null
}

const initialState: SearchState = {
  product: {
    result: [],
    totalResults: 0,
    facets: {},
    allFacets: {},
    sort: defaultProductSortOptionSearch,
    category: null,
    facetKeys: [],
    filters: {},
    filterCount: 0,
  },
  content: {
    sort: defaultContentSortOption,
    result: [],
    totalResults: 0,
  },
  type: 'products',
  query: '',
  initialized: false,
  loading: true,
  error: null,
}

interface ToggleFilterParams {
  id: string
  value: string
  open: boolean
}

interface SearchContext {
  actions: {
    toggleFilter: (p: ToggleFilterParams) => void
    clearFilters: () => void
    setSort: (sort: string) => void
    setType: (type: SearchType) => void
  }
  setState?: Updater<SearchState>
  state: SearchState
}

const context = createContext<SearchContext>({ state: initialState } as any)

interface Props {
  category?: string | null
  facetKeys: string[]
  children?: React.ReactNode
}

const Context: FC<Props> = ({ children, category, facetKeys }) => {
  const s = { ...initialState, product: { ...initialState.product, facetKeys } }
  const [state, setState] = useImmer(s)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { query, filters, sort, type } = parseFromQueryString({
        category,
        facetKeys,
      })
      setState((s) => {
        s.query = query
        s.product.facetKeys = facetKeys
        s.product.category = category ?? null
        s.product.filters = filters
        s.product.filterCount = countFilters(filters)
        s.type = type
        s.initialized = true
        if (type === 'products') {
          s.product.sort = sort as ProductSortOptionsType
        } else {
          s.content.sort = sort as ContentSortOptionsType
        }
      })
    }
  }, [category, facetKeys, setState])

  const toggleFilter = ({ id, open, value }: ToggleFilterParams) =>
    setState((s) => {
      if (!s.product.filters[id]) {
        s.product.filters[id] = {}
      }
      s.product.filters[id][value] = open
      s.product.filterCount = countFilters(s.product.filters)
      setQueryString(stateToQueryStringProps(s))
    })

  const countFilters = (filters: Dictionary<Dictionary<boolean>>) =>
    Object.values(filters).reduce((a, v) => {
      if (!v) return a
      a += Object.values(v).reduce((aa, vv) => {
        if (vv) {
          aa++
        }
        return aa
      }, 0)
      return a
    }, 0)

  const clearProductFilters = () =>
    setState((s) => {
      s.product.filters = {}
      s.product.filterCount = 0
      setQueryString(stateToQueryStringProps(s))
    })

  const setSort = (payload: string) =>
    setState((s) => {
      if (s.type === 'products') {
        s.product.sort = payload as any
      } else {
        s.content.sort = payload as any
      }
      setQueryString(stateToQueryStringProps(s))
    })

  const setType = (payload: SearchType) =>
    setState((s) => {
      s.type = payload
      setQueryString(stateToQueryStringProps(s))
    })

  return (
    <context.Provider
      value={{
        actions: {
          toggleFilter,
          clearFilters: clearProductFilters,
          setSort,
          setType,
        },
        setState,
        state,
      }}
    >
      {children}
    </context.Provider>
  )
}

export const useSearchContext = () => useContext(context)

interface UseSearchProductProps {
  locale: string
  noInitialResults: boolean
  categoryId?: string | null
}
export const useProductSearchResult = ({
  locale,
  noInitialResults,
  categoryId,
}: UseSearchProductProps) => {
  const {
    state: {
      product: { allFacets, filters, facetKeys, category, sort, result },
      initialized,
      loading,
      error,
      query,
    },
    setState,
  } = useContext(context)

  useEffect(() => {
    const search = async () => {
      if (!initialized || !setState) return
      let facets = allFacets
      const hasFacets = Object.keys(allFacets).length > 0
      setState((s) => {
        s.loading = true
        s.error = null
      })
      const hasFilters = Object.keys(filters).length > 0
      if (!hasFilters || noInitialResults || !hasFacets) {
        const facetsOnly = noInitialResults || hasFilters
        const i = await initialProductSearch({
          facetKeys,
          category: categoryId || '',
          sort,
          query,
          facetsOnly,
          locale,
        })

        // Set all facets
        facets = i.facets

        setState((s) => {
          s.product.allFacets = i.facets
          if (!hasFilters || noInitialResults) {
            s.product.result = i.result
            s.product.facets = i.facets
            s.product.totalResults = i.totalResults
            s.loading = false
            s.error = null
            s.product.category = category
          }
        })

        // If there's not filters, we don't need to do a 2nd filtered search
        if (!hasFilters || noInitialResults) {
          return
        }
      }

      const f = await filteredProductSearch({
        facetKeys,
        category: categoryId || '',
        sort,
        query,
        locale,
        filters,
        facets,
      })
      setState((s) => {
        s.product.allFacets = facets
        s.product.result = f.result
        s.product.facets = f.facets
        s.product.totalResults = f.totalResults
        s.loading = false
        s.error = null
        s.product.category = category
      })
    }
    search()
    // We don't want this effect to re-run if allFacets changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // allFacets,
    // category,
    // facetKeys,
    // locale,
    filters,
    initialized,
    noInitialResults,
    query,
    setState,
    sort,
  ])

  return {
    result,
    loading: loading || !initialized,
    error: error,
  }
}

interface UseSearchContentProps {
  locale: string
}

export const useContentSearchResult = ({ locale }: UseSearchContentProps) => {
  const {
    state: {
      initialized,
      loading,
      error,
      query,
      content: { sort, result },
    },
    setState,
  } = useContext(context)

  useEffect(() => {
    const search = async () => {
      if (!initialized || !setState) return
      const r = await contentSearch({
        sort,
        query,
        locale,
        size: 200,
      })
      setState((s) => {
        s.content.result = r.result
        s.content.totalResults = r.totalResults
      })
    }
    search()
  }, [initialized, locale, query, setState, sort])

  return {
    result,
    loading: loading || !initialized,
    error: error,
  }
}

function stateToQueryStringProps(s: SearchState): SetQueryStringProps {
  if (s.type === 'products') {
    return {
      query: s.query,
      type: s.type,
      filters: s.product.filters,
      sort: s.product.sort,
    }
  }
  return { query: s.query, type: s.type, sort: s.content.sort, filters: {} }
}
export default Context
