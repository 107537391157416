import {
  ElasticAppSearchQuery,
  ElasticAppSearchResultItem,
} from '@elastic/app-search-javascript'
import { PartialContent } from 'framework/common/types'
import { getLanguageFromChannel } from 'utils/localization'
import { getSearchClient } from '.'
import { ElasticSearchStory } from './types'
import { parseSort, parseUrlFromElastic } from './utils'

interface ContentSearchProps {
  locale: string
  query: string
  sort?: string
  size?: number
}

export const contentSearch = async ({
  query,
  locale,
  sort,
  size = 10,
}: ContentSearchProps) => {
  if (!locale) {
    throw Error('Locale not set when trying to do a search')
  }
  const q: ElasticAppSearchQuery = {
    query,
    search_fields: {
      title: {
        weight: 10,
      },
      slug: {
        weight: 4,
      },
      tags: {
        weight: 1,
      },
    },
    page: {
      size,
    },
    filters: {
      all: [{ channel: ['*', locale] }],
      none: [{ channel: [`!${locale}`] }],
    },
    sort: parseSort(sort),
    result_fields: {
      title: { snippet: { fallback: true } },
      slug: { raw: {} },
      type: { raw: {} },
    },
  }
  const lang = getLanguageFromChannel(locale)
  const client = getSearchClient(lang, 'cms')
  const r = await client.search<ElasticSearchStory>(query, q)

  return {
    result: r.results.map((rr) => mapContentResult(rr, locale)),
    totalResults: r.info.meta.page.total_results,
  }
}

const mapContentResult = (
  { data: d }: ElasticAppSearchResultItem<ElasticSearchStory>,
  locale: string,
): PartialContent => {
  const c: PartialContent = {
    id: d.id.raw!,
    title: d.title.snippet ?? '',
    slug: d.slug.raw
      ? parseUrlFromElastic(getContentUrl(d.type.raw, d.slug.raw), locale)
      : '/',
  }
  return c
}

const getContentUrl = (type?: string, slug?: string) => {
  if (!slug) {
    return '/'
  }
  if (type === 'story') {
    return `stories/${slug}`
  }
  return slug
}
