import Container from 'components/ui/Container'
import React, {
  ButtonHTMLAttributes,
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useAppSelector } from 'store'
import FilterButton from './FilterButton'
import Section from 'components/common/Section'
import { Lists, Category, CommonLink } from 'framework/strapi/types'
import translate from 'utils/translate'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Reveal from 'components/common/Reveal'
import cn from 'classnames'
import dynamic from 'next/dynamic'
import SearchContext, { useSearchContext } from './SearchContext'
import { useRouter } from 'next/router'
import DynamicZone from 'components/DynamicZone'
import Background from 'components/common/Background'
import { decode } from 'he'
import ButtonWrapper from 'components/ui/ButtonWrapper'
import Button from 'components/ui/Button'
import { isComponentPublishedInChannel } from 'utils/isInChannel'
import useUIStore from 'store/ui'
import BreadCrumbs from 'components/ui/BreadCrumbs'
import { BreadCrumb, zBreadCrumb } from 'framework/common/variantViewModel'

interface Props {
  facets?: string[]
  lists: Lists[]
  category?: Category
  searchValue?: string | undefined
}

const FilterDrawer = dynamic(() => import('./FilterDrawer'), { ssr: false })
const ProductSearchResults = dynamic(() => import('./ProductSearchResults'), {
  ssr: false,
})
const ContentSearchResult = dynamic(() => import('./ContentSearchResults'), {
  ssr: false,
})

const SearchComponent: FC<Props> = ({
  facets,
  lists,
  category,
  searchValue,
}) => {
  if (!facets) {
    console.error('facets where not found', facets)
    return null
  }
  return (
    <SearchContext category={category?.code} facetKeys={facets}>
      <FilterDrawer />
      <Inner category={category} searchValue={searchValue} lists={lists} />
    </SearchContext>
  )
}

const Inner: FC<Props> = ({ category, searchValue, lists }) => {
  const ui = useUIStore()
  const locale = useAppSelector((s) => s.channel.locale)
  // Show / Hide Breadcrumbs on scroll
  const [showBreadcrumbs, setShowBreadcrumbs] = useState(false)
  const router = useRouter()
  useEffect(() => {
    // Fix for internal routing on search page. NextJS don't know if we are routing from one search page to another
    // When only the query differs. If that's the case we neeed to reload the page for now.
    const handler = (url: string) => {
      if (
        router.pathname === '/search' &&
        url.includes('/search') &&
        url != window.location.pathname + window.location.search
      ) {
        window.location.replace(url)
      }
    }
    router.events.on('routeChangeStart', handler)
    return () => {
      router.events.off('routeChangeStart', handler)
    }
  }, [router])

  useScrollPosition(({ prevPos, currPos }) => {
    const y = -currPos.y
    const py = -prevPos.y

    // Scrolling up
    if (y < py) {
      setShowBreadcrumbs(false)
    }

    // Scrolling down
    if (y > py && y > 450) {
      setShowBreadcrumbs(true)
    }
  })

  const {
    state: {
      type,
      product: { filterCount },
    },
    actions: { setType },
  } = useSearchContext()

  const content = (
    <>
      <Container layout="Fluid">
        <div className="flex flex-col flex-wrap items-start justify-between gap-x-10 gap-y-8 lg:gap-y-10">
          <div className="flex flex-col gap-y-3 md:gap-y-5">
            <h1 className="font-bold text-white text-h-3xl md:text-h-4xl lg:text-h-6xl">
              {category ? decode(category.name) : translate('search')}
            </h1>
            {category?.text && (
              <p className="max-w-md text-base font-medium leading-normal text-white md:text-lg">
                {category.text}
              </p>
            )}
            {searchValue && (
              <p className="max-w-md text-base font-medium leading-normal text-white md:text-lg">
                {translate('resultsFor')}: {decode(searchValue)}
              </p>
            )}
          </div>

          <div className="flex flex-wrap justify-between w-full gap-4">
            {category?.ctas && category.ctas.length > 0 && (
              <ButtonWrapper>
                {category.ctas.map(
                  (cta: CommonLink, index: number) =>
                    cta.title &&
                    cta.url?.src && (
                      <Button
                        key={index}
                        href={cta.url.src}
                        color={'secondary'}
                        // color={index === 0 ? 'primary' : 'secondary'}
                        colorTheme={'Dark'}
                        size="lg"
                      >
                        {cta.title}
                      </Button>
                    ),
                )}
              </ButtonWrapper>
            )}
            <FilterButton
              onClick={() => ui.open('filter')}
              filterCount={type === 'products' ? filterCount : 0}
              colorTheme="Dark"
              size="lg"
            />
          </div>
        </div>
      </Container>
    </>
  )

  const header = (
    <div
      className={cn('relative pb-6 pt-20 md:pb-14', {
        'pt-48': !searchValue,
        'bg-primary': !category?.background,
      })}
    >
      {category?.background && <Background {...category.background} />}
      {content}
    </div>
  )

  const filteredComponents = useMemo(
    () =>
      category?.main?.filter((s) => isComponentPublishedInChannel(locale, s)) ??
      [],
    [category?.main, locale],
  )

  const breadCrumbs = useMemo<BreadCrumb[]>(
    () =>
      category?.breadcrumbs
        ? zBreadCrumb.array().parse(category?.breadcrumbs)
        : [],
    [category?.breadcrumbs],
  )

  return (
    <>
      <Section>{header}</Section>
      {category?.main && filterCount < 1 && (
        <div className="mt-10 md:mt-14 lg:mt-20">
          <DynamicZone
            components={filteredComponents}
            preview={router.isPreview}
            floatingNavigation={false}
          />
        </div>
      )}
      {searchValue && (
        <div
          className="flex justify-center gap-4 mt-8"
          role="tablist"
          aria-orientation="horizontal"
        >
          <ResultTab
            name={translate('products')}
            aria-selected={type === 'products'}
            onClick={() => setType('products')}
          />
          <ResultTab
            name={translate('pages')}
            aria-selected={type === 'content'}
            onClick={() => setType('content')}
          />
        </div>
      )}
      {type === 'products' ? (
        <ProductSearchResults
          category={category}
          lists={lists}
          cmsContentLength={filteredComponents.length}
        />
      ) : (
        <ContentSearchResult />
      )}
      <Reveal
        isOpen={showBreadcrumbs}
        className="transition-opacity duration-200"
        closedClassName="opacity-0"
        openClassName="opacity-100"
      >
        <div className="fixed top-0 left-0 z-20 flex items-center w-full py-4 min-h-16 lg:h-18 xl:h-20 bg-primary">
          <Container layout="Fluid">
            <div className="flex items-center">
              <div className="flex flex-wrap items-center justify-between flex-1 gap-x-4 gap-y-4">
                <BreadCrumbs colorTheme={'Dark'} breadCrumbs={breadCrumbs} />
                <FilterButton
                  onClick={() => ui.open('filter')}
                  filterCount={type === 'products' ? filterCount : 0}
                  colorTheme="Dark"
                />
              </div>
            </div>
          </Container>
        </div>
      </Reveal>
    </>
  )
}

const ResultTab: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  name,
  className,
  ...rest
}) => {
  return (
    <button
      className={cn(
        className,
        'inline-block font-bold 2xl:text-xl duration-300 text-primary hover:border-b-primary',
        {
          'border-primary border-b-2': rest['aria-selected'],
          'border-b-2 border-transparent': !rest['aria-selected'],
        },
      )}
      {...rest}
    >
      {name}
    </button>
  )
}

export default SearchComponent
